<template>
  <v-row justify="center">
    <v-dialog
      v-model="shower"
      scrollable
      persistent
      max-width="600px"
      v-if="resolutionScreen >= 500"
    >
      <v-card>
        <v-card-title>
          <v-layout row wrap>
            <v-flex lg11 sm11 xs10 class="pl-2">
              <v-list-item-title style="font-size: 18px;">{{
                $t("smartinput.selectfoldertemplate")
              }}</v-list-item-title>
            </v-flex>
            <v-flex lg1 sm1 xs2>
              <v-icon
                class="text-right"
                :color="color.theme"
                dense
                dark
                size="35"
                >mdi-folder-edit-outline</v-icon
              >
            </v-flex>
          </v-layout>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mb-n4 mt-3">
          <v-layout row wrap>
            <v-flex lg6 class="pl-6">
              <v-chip class="mb-n2" :color="color.theme" text-color="white">
                <v-btn
                  :disabled="enableback"
                  @click="
                    searchchildfolder_back(backto_folder, account_active_inbiz)
                  "
                  style="cursor: pointer; background-color: transparent !important;"
                  icon
                  color="white"
                  class="ml-n1 mr-n2"
                >
                  <v-icon>mdi-arrow-left-circle</v-icon>
                </v-btn>
                <span class="ml-4" v-if="resolutionScreen >= 400">{{
                  parentname_biz
                }}</span>
                <span class="ml-4" v-else>{{
                  parentname_biz.length >= 30
                    ? parentname_biz.substring(0, 30) + "..."
                    : parentname_biz
                }}</span>
              </v-chip>
            </v-flex>
            <v-flex lg6 class="text-right pr-6">
              <v-btn
                class="white--text"
                color="#ffa000"
                @click="$emit('createfolder', currentfolderid)"
              >
                <v-icon dense class="mr-1">
                  mdi-folder-plus
                </v-icon>
                {{ $t("smartinput.createnewfolder") }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-text style="height: 400px;">
          <v-layout row wrap class="pa-4 mb-n12 mt-n2">
            <v-flex v-if="createprogress === false" class="mb-n12">
              <v-list nav dense v-if="listbusiness.length > 0">
                <v-list-item-group
                  v-model="item_"
                  :color="color.theme"
                  @change="clicker"
                >
                  <v-list-item
                    v-for="(item_, i) in listbusiness"
                    :key="i"
                    :disabled="
                      fn_permission_folder(item_.permission_account_v2)
                    "
                    @dblclick="
                      querydirectories(
                        item_.folder_id === '' ||
                          item_.folder_id === '' ||
                          item_.folder_id === undefined
                          ? this.moveto
                          : item_.folder_id,
                        item_
                      ),
                        (status_get_business = false)
                    "
                  >
                    <v-list-item-icon>
                      <v-icon
                        :color="
                          item_.folder_status === 'M' ? 'green' : '#ffc107'
                        "
                        :disabled="
                          fn_permission_folder(item_.permission_account_v2)
                        "
                        large
                        >folder</v-icon
                      >
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        item_.folder_name
                      }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action
                      v-if="fn_permission_folder(item_.permission_account_v2)"
                    >
                      <v-chip x-small class="py-2">
                        {{ $t("smartinput.permission_denied") }}
                      </v-chip>
                    </v-list-item-action>
                    <v-list-item-icon
                      v-if="item_.folder_id === newfolder.folder_id"
                    >
                      <v-icon color="red">
                        mdi-new-box
                      </v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <v-list nav dense v-else>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-center">{{
                      $t("movefiles.nofolder")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-flex>
            <v-flex v-else>
              <v-layout justify-center>
                <br />
                <v-progress-circular
                  class="mt-6 mb-6 pt-6 pb-6"
                  :size="50"
                  :dark="color.darkTheme"
                  :color="color.theme"
                  indeterminate
                ></v-progress-circular>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            outlined
            @click="$emit('closenoreload'), cleardata()"
            :disabled="createprogress"
            >{{ $t("movefiles.buttonclose") }}</v-btn
          >
          <v-btn
            :color="color.theme"
            @click="$emit('closenoreload'), checkFolderTemplate()"
            :loading="createprogress"
            :disabled="disablemove || disabledmainfolder"
            class="white--text"
          >
            {{ wordingbutton }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- หน้าจอมือถือ -->
    <v-dialog
      v-model="shower"
      scrollable
      persistent
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
      v-else
    >
      <v-card>
        <div class="mx-2">
          <v-card-text class="pa-5">
            <v-layout class="pt-2 pb-4">
              <v-flex d-flex justify-center class="ml-8">
                <span :style="headerPage">{{
                  $t("smartinput.selectfoldertemplate")
                }}</span>
              </v-flex>
              <v-flex xs1 d-flex justify-end>
                <v-icon
                  :color="$vuetify.theme.dark ? 'white' : color.theme"
                  @click="$emit('closenoreload'), cleardata()"
                  >mdi-close</v-icon
                >
              </v-flex>
            </v-layout>
            <v-chip
              v-if="createprogress === false"
              :style="headerChipPage"
              :color="$vuetify.theme.dark ? '#989898' : color.theme"
            >
              <v-btn
                :disabled="enableback"
                @click="
                  searchchildfolder_back(backto_folder, account_active_inbiz)
                "
                style="cursor: pointer; background-color: transparent !important;"
                icon
                color="white"
                class="ml-n1 mr-3"
              >
                <v-icon
                  :color="$vuetify.theme.dark ? 'white' : 'white'"
                  dark
                  size="25"
                  >mdi-arrow-left-circle</v-icon
                >
              </v-btn>
              <v-list-item-title class="ml-n2">
                <span :style="headerPageMobile" v-if="resolutionScreen >= 400">
                  {{ parentname_biz }}</span
                >
                <span :style="headerPageMobile" v-else>{{
                  parentname_biz.length >= 30
                    ? parentname_biz.substring(0, 30) + "..."
                    : parentname_biz
                }}</span>
              </v-list-item-title>
            </v-chip>
            
            <v-layout row wrap>
              <v-flex xs12 class="text-right mt-2">
                <v-btn
                  class="white--text"
                  color="#ffa000"
                  small=""
                  @click="$emit('createfolder', currentfolderid)"
                >
                  <v-icon dense class="mr-1">
                    mdi-folder-plus
                  </v-icon>
                  {{ $t("smartinput.createnewfolder") }}
                </v-btn>
            </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-text style="height: 400px; overflow-y: scroll;">
            <v-layout class="mt-n6" row wrap>
              <v-flex v-if="createprogress === false">
                <v-list nav dense v-if="listbusiness.length > 0">
                  <v-list-item-group
                    v-model="item_"
                    :color="color.theme"
                    @change="clicker"
                  >
                    <v-list-item
                      v-for="(item_, i) in listbusiness"
                      :key="i"
                      :disabled="
                        fn_permission_folder(item_.permission_account_v2)
                      "
                      @dblclick="
                        querydirectories(
                          item_.folder_id === '' ||
                            item_.folder_id === '' ||
                            item_.folder_id === undefined
                            ? this.moveto
                            : item_.folder_id,
                          item_
                        ),
                          (status_get_business = false)
                      "
                    >
                      <v-list-item-icon>
                        <v-icon
                          :disabled="
                            fn_permission_folder(item_.permission_account_v2)
                          "
                          :color="
                            item_.folder_status === 'M' ? 'green' : '#ffc107'
                          "
                          large
                          >folder</v-icon
                        >
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          item_.folder_name
                        }}</v-list-item-title>
                      </v-list-item-content>
                      <!-- Add new permission chip -->
                      <v-list-item-action
                        v-if="fn_permission_folder(item_.permission_account_v2)"
                      >
                        <v-chip x-small class="py-2">
                          {{ $t("smartinput.permission_denied") }}
                        </v-chip>
                      </v-list-item-action>
                      <v-list-item-icon
                      v-if="item_.folder_id === newfolder.folder_id"
                    >
                      <v-icon color="red">
                        mdi-new-box
                      </v-icon>
                    </v-list-item-icon>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
                <v-list nav dense v-else>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="text-center">{{
                        $t("movefiles.nofolder")
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-flex>
              <v-flex v-else>
                <v-layout justify-center>
                  <br />
                  <v-progress-circular
                    class="mt-6 mb-6 pt-6 pb-6"
                    :size="50"
                    :dark="color.darkTheme"
                    :color="color.theme"
                    indeterminate
                  ></v-progress-circular>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card-text>
        </div>
        <div class="text-center my-4">
          <v-btn
            :style="btnAction"
            class="mr-2"
            height="40px"
            width="40%"
            color="red"
            outlined
            @click="$emit('closenoreload'), cleardata()"
            :disabled="createprogress"
            >{{ $t("movefiles.buttonclose") }}</v-btn
          >
          <v-btn
            class="elevation-0 white--text"
            :style="btnAction"
            height="40px"
            width="40%"
            :color="color.theme"
            @click="$emit('closenoreload'), checkFolderTemplate()"
            :loading="createprogress"
            :disabled="disablemove || disabledmainfolder"
          >
            {{ wordingbutton }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { mapState, mapGetters } from "vuex";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import { log } from "console";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  components: {},
  props: ["show", "parentfolder", "newfolder"],
  data: function() {
    return {
      enableback: false,
      createprogress: false,
      listdirectory: [],
      parentdirectory: "",
      parentname: "",
      parentname_biz: "",
      item: "",
      item_: "",
      moveto: "",
      listbusiness: [],
      tab: 0,
      checktab: 1,
      search: "",
      folderid: "",
      backto_folder: "",
      account_active_inbiz: "",
      full_path: "",
      first_opendialog: true,
      wordingbutton: this.$t("smartinput.selecthere"),
      business_name_th: "",
      business_name_en: "",
      disablemove: false,
      status_get_business: false,
      currentfolderid: "",
      disabledmainfolder: true,
    };
  },
  watch: {
    newfolder: function() {
      this.searchchildfolder_biz(this.currentfolderid, this.dataAccountId);
    },
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
          console.log("dataAccountActive", this.dataAccountId);
          this.createprogress = true;
          this.searchchildfolder_biz(
            this.dataAccountActive.business_info.my_folder_id,
            this.dataAccountId
          );
          this.business_name_th = this.dataAccountActive.business_info.first_name_th;
          console.log("this.dataAccountActive.business_infot", this.dataAccountActive.business_info);
          this.business_name_en = this.dataAccountActive.business_info.first_name_eng;
          this.$emit("closeDrag");
          return this.show;
        } else {
          return false;
        }
      },
      set(value) {
        if (!value) {
          this.first_opendialog = true;
          this.checktab = 1;
          this.tab = 0;
          this.$emit("closedbmovefile");
          this.$emit("openDrag");
        }
      },
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    // style อักษร header ของ mobile
    headerPage() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    // style ปุ่ม ของ mobile
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    // style อักษรใน chip ของ mobile
    headerPageMobile() {
      return "color:white;" + "font-size: 16px; line-height: 24px;";
    },
    // style chip ของ mobile
    headerChipPage() {
      return "width: 100%; min-height: 40px; max-height: auto; height: auto !important; border-radius: 40px;";
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if (this.resolutionScreen >= 400) {
        return 600;
      } else {
        return 346;
      }
    },
  },
  methods: {
    checkFolderTemplate() {
      if (this.item_ === undefined || this.item_ === "") {
        let path = this.full_path.split("/");
        path.shift();
        path = path.join("/");
        let data = {
          folder_name: this.parentname_biz,
          path: path + "/",
        };
        this.$emit("submitfoldertemplate", data);
      } else {
        console.log(
          "this.listbusiness[this.item_]",
          this.listbusiness[this.item_]
        );
        let path = this.listbusiness[this.item_].full_path.split("/");
        path.shift();
        path = path.join("/");
        let data = {
          folder_name: this.listbusiness[this.item_].folder_name,
          path: path + "/",
        };
        this.$emit("submitfoldertemplate", data);
      }
    },
    fn_permission_folder(permission_text) {
      //console.log("permission text ", permission_text)
      if (permission_text !== undefined) {
        let permission_number = permission_text.split("")[1];
        if (permission_number < 3) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    fn_permission_denied() {
      Toast.fire({
        icon: "error",
        title: this.$t("multismartinput.permission_denied"),
      });
      this.$emit("closedbmovefile");
      this.$emit("openDrag");
    },
    clicker() {
      if (this.item_ === undefined || this.item_ === "") {
        this.wordingbutton = this.$t("smartinput.selecthere");
        if (this.enableback === true) {
          this.disabledmainfolder = true;
        }
      } else {
        this.wordingbutton = this.$t("smartinput.select");
        if (this.enableback === true) {
          this.disabledmainfolder = false;
        }
      }
    },
    cleardata() {
      this.createprogress = false;
      this.listdirectory = [];
      this.parentdirectory = "";
      this.parentname = "";
      this.moveto = "";
      this.item = "";
      this.listbusiness = [];
      this.tab = 0;
      this.checktab = 1;
      this.item = "";
      this.item_ = "";
      this.backto_folder = "";
      this.account_active_inbiz = "";
      this.first_opendialog = true;
      this.business_name_th = "";
      this.business_name_en = "";
      this.currentfolderid = "";
    },
    querydirectories(parameter) {
      this.createprogress = true;
      this.disablemove = false;
      this.enableback = false;
      setTimeout(() => {
        this.searchchildfolder_biz(parameter, this.dataAccountId);
      }, 500);
    },
    async searchchildfolder_biz(folderid, account_id) {
      this.listbusiness = [];
      this.targetfolder = "";
      this.full_path = "";
      this.currentfolderid = folderid;
      this.currentaccountid = account_id;
      let payload = {
        user_id: this.dataUsername,
        folder_id: folderid,
        account_id: account_id,
        status_folder: "",
      };
      console.log("payload searchdirectory", payload);
      let auth = await gbfGenerate.generateToken();
      let apiMoveFolder = "/api/v2/search_folder_byid_admin_ignore";
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_SELECT_FOLDER + apiMoveFolder,
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            // this.targetfolder = Folder ID ที่ต้องการย้าย
            console.log("res", response.data);
            console.log(
              "response.data.folder.length",
              response.data.folder.length
            );
            if (response.data.folder.length > 0) {
              if (response.data.your_folder[0]["folder_status"] === "R") {
                this.moveto = response.data.your_folder[0]["folder_id"];
                this.parentdirectory =
                  response.data.your_folder[0]["account_id"];
                this.folderid = response.data.your_folder[0]["account_id"];
                this.enableback = true;
                this.disabledmainfolder = true;
                this.backto_folder =
                  response.data.your_folder[0]["parent_folder_id"];
                // ให้แสดงเป็นชื่อบริษัทที่เลือก
                if (this.$t("default") === "th") {
                  this.parentname_biz = this.business_name_th;
                } else {
                  this.parentname_biz = this.business_name_en;
                }
                this.account_active_inbiz =
                  response.data.your_folder[0]["account_id"];
                this.full_path = response.data.your_folder[0]["full_path"];
              } else {
                // ? ถ้าเป็น null ให้ไปที่อยู่ของโฟลเดอร์มัน
                this.moveto = response.data.your_folder[0]["folder_id"];
                // this.parentdirectory = response.data.folder[0]["account_id"];
                this.parentdirectory =
                  response.data.your_folder[0]["account_id"];
                this.folderid = response.data.your_folder[0]["account_id"];
                this.enableback = false;
                this.disabledmainfolder = false;
                // this.backto_folder = response.data.your_folder[0]["folder_id"];
                this.backto_folder =
                  response.data.your_folder[0]["parent_folder_id"];
                //! ของเดิมใช้ folder_name
                this.parentname_biz =
                  response.data.your_folder[0]["folder_name"];
                this.account_active_inbiz =
                  response.data.your_folder[0]["account_id"];
                this.full_path = response.data.your_folder[0]["full_path"];
              }
              let i;
              let data_new_folder;
              let create_id =
                this.newfolder.folder_id !== undefined
                  ? this.newfolder.folder_id
                  : "";
              for (i = 0; i < response.data.folder.length; i++) {
                if (response.data.folder[i].folder_id === create_id) {
                  data_new_folder = response.data.folder[i];
                } else if (response.data.folder[i].folder_status === "M") {
                  continue;
                } else {
                  this.listbusiness.push(response.data.folder[i]);
                }

                if (i + 1 === response.data.folder.length && data_new_folder) {
                  this.listbusiness.unshift(data_new_folder);
                }
              }
            } else {
              if (response.data.your_folder[0]["folder_status"] === "R") {
                this.enableback = true;
                this.disabledmainfolder = true;
              } else {
                this.enableback = false;
                this.disabledmainfolder = false;
              }
              response.data.your_folder[0]["folder_status"] === "R"
                ? (this.$t("default") === "th" ? this.parentname_biz = this.business_name_th : his.parentname_biz = this.business_name_en)
                : (this.parentname_biz =
                    response.data.your_folder[0]["folder_name"]);
              this.backto_folder =
                response.data.your_folder[0]["parent_folder_id"];
              this.parentdirectory = response.data.your_folder[0]["account_id"];
              this.folderid = response.data.your_folder[0]["account_id"];
              this.account_active_inbiz =
                response.data.your_folder[0]["account_id"];
              this.full_path = response.data.your_folder[0]["full_path"];
              this.moveto = response.data.your_folder[0]["folder_id"];
            }
            this.item = "";
            this.clicker();
            this.createprogress = false;
          } else {
            this.listbusiness = [];
            this.createprogress = false;
            console.log("");
          }
        })
        .catch((err) => {
          this.listbusiness = [];
          this.createprogress = false;
          console.log(err);
        });
    },
    async searchchildfolder_back(folderid, account_active_inbizs) {
      this.createprogress = true;
      this.listbusiness = [];
      this.targetfolder = "";
      let payload = {
        user_id: this.dataUsername,
        folder_id: folderid,
        // account_id:this.dataAccountId,
        account_id: account_active_inbizs,
        status_folder: "",
      };
      console.log("payload searchdirectory", payload);
      let auth = await gbfGenerate.generateToken();
      let apiMoveFolder = "/api/v2/search_folder_byid_admin_ignore";
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_SELECT_FOLDER + apiMoveFolder,
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            if (response.data.folder.length > 0) {
              //กรณีที่ F

              if (response.data.your_folder[0]["folder_status"] === "R") {
                console.log("R&Citizen");
                this.moveto = response.data.your_folder[0]["folder_id"];
                this.parentdirectory =
                  response.data.your_folder[0]["account_id"];
                this.enableback = true;
                this.disabledmainfolder = true;
                this.folderid = response.data.your_folder[0]["account_id"];
                this.account_active_inbiz =
                  response.data.your_folder[0]["account_id"];
                this.backto_folder =
                  response.data.your_folder[0]["parent_folder_id"];
                // ให้แสดงเป็นชื่อบริษัทที่เลือก
                if (this.$t("default") === "th") {
                  this.parentname_biz = this.business_name_th;
                } else {
                  this.parentname_biz = this.business_name_en;
                }
                this.full_path = response.data.your_folder[0]["full_path"];
              } else {
                // ? ถ้าเป็น null ให้ไปที่อยู่ของโฟลเดอร์มัน
                this.moveto = response.data.your_folder[0]["folder_id"];
                this.parentdirectory =
                  response.data.your_folder[0]["account_id"];
                this.folderid = response.data.your_folder[0]["account_id"];
                this.enableback = false;
                this.disabledmainfolder = false;
                this.account_active_inbiz =
                  response.data.your_folder[0]["account_id"];
                // this.backto_folder =
                //   response.data.your_folder[0]["folder_id"];
                this.backto_folder =
                  response.data.your_folder[0]["parent_folder_id"];
                //! ของเดิมใช้ folder_name
                this.parentname_biz =
                  response.data.your_folder[0]["folder_name"];
                this.full_path = response.data.your_folder[0]["full_path"];
              }
              let i;
              let data_new_folder;
              let create_id =
                this.newfolder.folder_id !== undefined
                  ? this.newfolder.folder_id
                  : "";
              for (i = 0; i < response.data.folder.length; i++) {
                if (response.data.folder[i].folder_id === create_id) {
                  data_new_folder = response.data.folder[i];
                } else if (response.data.folder[i].folder_status === "M") {
                  continue;
                } else {
                  this.listbusiness.push(response.data.folder[i]);
                }

                if (i + 1 === response.data.folder.length && data_new_folder) {
                  this.listbusiness.unshift(data_new_folder);
                }
              }
              console.log(
                "this.account_active_inbiz1",
                this.account_active_inbiz
              );
            } else {
              if (response.data.your_folder[0]["folder_status"] === "R") {
                this.enableback = true;
                this.disabledmainfolder = true;
              } else {
                this.enableback = false;
                this.disabledmainfolder = false;
              }
              response.data.your_folder[0]["folder_status"] === "R"
                ? (this.$t("default") === "th" ? this.parentname_biz = this.business_name_th : his.parentname_biz = this.business_name_en)
                : (this.parentname_biz =
                    response.data.your_folder[0]["folder_name"]);
              this.backto_folder =
                response.data.your_folder[0]["parent_folder_id"];
              console.log("this.folderid", this.folderid);
              this.account_active_inbiz =
                response.data.your_folder[0]["account_id"];
              console.log(
                "this.account_active_inbiz2",
                this.account_active_inbiz
              );
              this.full_path = response.data.your_folder[0]["full_path"];
            }
            this.createprogress = false;
          } else {
            this.listbusiness = [];
            this.createprogress = false;
            console.log("");
          }
          this.item_ = "";
          this.clicker();
        })
        .catch((err) => {
          this.listbusiness = [];
          this.createprogress = false;
          console.log(err);
        });
    },
  },
};
</script>
<style></style>
